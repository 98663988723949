import { IconAlertTriangle, IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import React from 'react';
import { ApiError, OpenAPI, Prestacao } from '../slidein_api';
import { MRT_SortingState } from 'mantine-react-table';
import { Button, List, SelectItem, Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import axios from 'axios';
import FileSaver from 'file-saver';

export type DetalheProps = { creation: boolean };

export function isFormFieldValid<ObjectType>(name: keyof ObjectType, form) {
  return !!(form.touched[name] && form.errors[name]);
}

export const msgAPIError = (reason: ApiError) => {
  console.error(reason);
  const ee = [];
  if (reason?.body) {
    if (reason.status === 500) {
      ee.push(`ERRO INTERNO SERVER : HORA:${new Date().toISOString()}`);
    } else {
      for (const [key, value] of Object.entries(reason.body)) {
        ee.push(`${key} : ${JSON.stringify(value, null, 2)}`);
      }
    }
  }
  if (reason?.body?.code === 'token_not_valid') {
    return;
  }
  modals.open({
    title: 'Erro durante a execução da Acção!',
    children: (
      <Stack>
        <List
          size='sm'
          withPadding>
          {ee.map((item, i) => (
            <List.Item key={i}>{JSON.stringify(item, null, 4)}</List.Item>
          ))}
        </List>
        <Button
          fullWidth
          color={'yellow'}
          variant={'outline'}
          leftIcon={<IconAlertTriangle />}
          onClick={modals.closeAll}
          mt='md'>
          Fechar
        </Button>
      </Stack>
    ),
  });
};

export const mrtSortingToAPI = (
  cenas: MRT_SortingState,
  trans: Record<string, string> = undefined
) => {
  const acc = [];
  cenas.forEach((c) => {
    acc.push((c.desc ? '-' : '') + (trans ? trans[c.id] : c.id));
  });
  return acc.join(',') || '';
};

export function removeNulls(obj: never): never | undefined {
  if (obj === null) {
    return undefined;
  }
  if (typeof obj === 'object') {
    for (const key in obj as object) {
      if (typeof obj[key] == typeof '' && obj[key] === null) {
        (obj as object)[key] = '';
      } else {
        (obj as object)[key] = removeNulls(obj[key]);
      }
    }
  }
  return obj;
}

export const iconBool = (testVar) =>
  testVar ? (
    <IconCircleCheck color={'green'} />
  ) : (
    <IconCircleX
      color={'red'}
      size={'1rem'}
    />
  );
export const iconBoolOnlyTrue = (testVar) =>
  testVar ? (
    <IconCircleCheck
      color={'green'}
      size={'1rem'}
    />
  ) : (
    ''
  );

export const fileLink = (name, fileurl) => (
  <a
    href={fileurl}
    target='_blank'
    rel='noreferrer'
    download>
    {name}
  </a>
);

export const down_xlsx = (url_path, filename = 'data.xlsx', query = {}, params = {}) => {
  const url = `${OpenAPI.BASE}${url_path}`;

  axios.get(url, { data: query, responseType: 'blob', params: params }).then(
    (res) => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      FileSaver.saveAs(blob, filename);
      return res;
    },
    (reason) => msgAPIError(reason)
  );
};

export type CalculatedPrestacao = {
  data: string;
  valor: number;
  valor_str: string;
  total_pago: number;
};

function fixp(nn) {
  return Math.round(nn * 100) / 100;
}

export function calculate_prestas(
  prestas: Prestacao[],
  valor_total: number,
  valor_seguro: number,
  valor_presta_minimo: number,
  valor_devido1: number
) {
  const ret_prestas: CalculatedPrestacao[] = [];
  let falta_pagar = valor_total;
  const valor_devido = Math.max(valor_devido1, 0);
  const pag_val = fixp(valor_total - valor_devido);

  prestas.forEach((pp, i) => {
    const pp_valor = parseFloat(pp.valor || '0');
    let valor = pp_valor;
    let valor_str = `${valor}€`;
    if (i == 0 && valor_seguro > 0) {
      valor = fixp(pp_valor + valor_seguro);
      valor_str = `${pp_valor} + ${valor_seguro} (seguro)=${valor}€`;
    }

    if (fixp(falta_pagar - valor) < valor_presta_minimo || pp.ajuste_de_contas) {
      valor = falta_pagar;
      valor_str = `${valor}€ (Restante)`;
    }

    if (valor > valor_devido && fixp(valor_total - falta_pagar) > pag_val) {
      valor = valor_devido;
      valor_str = `${valor_total} - ${fixp(valor_total - valor_devido)} = ${valor}€`;
    }

    falta_pagar = fixp(falta_pagar - valor);

    if (valor > 0)
      ret_prestas.push({
        data: pp.data_limite,
        valor_str: valor_str,
        valor: valor,
        total_pago: fixp(valor_total - falta_pagar),
      });
  });

  if (ret_prestas.length > 0) {
    return ret_prestas;
  } else {
    return [
      {
        data: null,
        valor_str: `${valor_total}€`,
        valor: valor_total,
        total_pago: valor_total,
      },
    ];
  }
}

export const paisList: SelectItem[] = [
  { label: 'Afeganistão', value: 'AF' },
  { label: 'África do Sul', value: 'ZA' },
  { label: 'Albânia', value: 'AL' },
  { label: 'Alemanha', value: 'DE' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Antiga e Barbuda', value: 'AG' },
  { label: 'Arábia Saudita', value: 'SA' },
  { label: 'Argélia', value: 'DZ' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Arménia', value: 'AM' },
  { label: 'Austrália', value: 'AU' },
  { label: 'Áustria', value: 'AT' },
  { label: 'Azerbaijão', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bangladexe', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Barém', value: 'BH' },
  { label: 'Bélgica', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benim', value: 'BJ' },
  { label: 'Bielorrússia', value: 'BY' },
  { label: 'Bolívia', value: 'BO' },
  { label: 'Bósnia e Herzegovina', value: 'BA' },
  { label: 'Botsuana', value: 'BW' },
  { label: 'Brasil', value: 'BR' },
  { label: 'Brunei', value: 'BN' },
  { label: 'Bulgária', value: 'BG' },
  { label: 'Burquina Faso', value: 'BF' },
  { label: 'Burúndi', value: 'BI' },
  { label: 'Butão', value: 'BT' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Camarões', value: 'CM' },
  { label: 'Camboja', value: 'KH' },
  { label: 'Canadá', value: 'CA' },
  { label: 'Catar', value: 'QA' },
  { label: 'Cazaquistão', value: 'KZ' },
  { label: 'Chade', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Chipre', value: 'CY' },
  { label: 'Colômbia', value: 'CO' },
  { label: 'Comores', value: 'KM' },
  { label: 'Congo-Brazzaville', value: 'CG' },
  { label: 'Coreia do Norte', value: 'KP' },
  { label: 'Coreia do Sul', value: 'KR' },
  { label: 'Costa do Marfim', value: 'CI' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Croácia', value: 'HR' },
  { label: 'Cuaite', value: 'KW' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Dinamarca', value: 'DK' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Egito', value: 'EG' },
  { label: 'Emirados Árabes Unidos', value: 'AE' },
  { label: 'Equador', value: 'EC' },
  { label: 'Eritreia', value: 'ER' },
  { label: 'Eslováquia', value: 'SK' },
  { label: 'Eslovénia', value: 'SI' },
  { label: 'Espanha', value: 'ES' },
  { label: 'Essuatíni', value: 'SZ' },
  { label: 'Estado da Palestina', value: 'PS' },
  { label: 'Estados Unidos da America', value: 'US' },
  { label: 'Estónia', value: 'EE' },
  { label: 'Etiópia', value: 'ET' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Filipinas', value: 'PH' },
  { label: 'Finlândia', value: 'FI' },
  { label: 'França', value: 'FR' },
  { label: 'Gabão', value: 'GA' },
  { label: 'Gâmbia', value: 'GM' },
  { label: 'Gana', value: 'GH' },
  { label: 'Geórgia', value: 'GE' },
  { label: 'Granada', value: 'GD' },
  { label: 'Grécia', value: 'GR' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guiana', value: 'GY' },
  { label: 'Guiné', value: 'GN' },
  { label: 'Guiné Equatorial', value: 'GQ' },
  { label: 'Guiné-Bissau', value: 'GW' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hungria', value: 'HU' },
  { label: 'Iémen', value: 'YE' },
  { label: 'Ilhas Marechal', value: 'MH' },
  { label: 'Índia', value: 'IN' },
  { label: 'Indonésia', value: 'ID' },
  { label: 'Irão', value: 'IR' },
  { label: 'Iraque', value: 'IQ' },
  { label: 'Irlanda', value: 'IE' },
  { label: 'Islândia', value: 'IS' },
  { label: 'Israel', value: 'IL' },
  { label: 'Itália', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japão', value: 'JP' },
  { label: 'Jibuti', value: 'DJ' },
  { label: 'Jordânia', value: 'JO' },
  { label: 'Laus', value: 'LA' },
  { label: 'Lesoto', value: 'LS' },
  { label: 'Letónia', value: 'LV' },
  { label: 'Líbano', value: 'LB' },
  { label: 'Libéria', value: 'LR' },
  { label: 'Líbia', value: 'LY' },
  { label: 'Listenstaine', value: 'LI' },
  { label: 'Lituânia', value: 'LT' },
  { label: 'Luxemburgo', value: 'LU' },
  { label: 'Macedónia do Norte', value: 'MK' },
  { label: 'Madagáscar', value: 'MG' },
  { label: 'Malásia', value: 'MY' },
  { label: 'Maláui', value: 'MW' },
  { label: 'Maldivas', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marrocos', value: 'MA' },
  { label: 'Maurícia', value: 'MU' },
  { label: 'Mauritânia', value: 'MR' },
  { label: 'México', value: 'MX' },
  { label: 'Mianmar', value: 'MM' },
  { label: 'Micronésia', value: 'FM' },
  { label: 'Moçambique', value: 'MZ' },
  { label: 'Moldávia', value: 'MD' },
  { label: 'Mónaco', value: 'MC' },
  { label: 'Mongólia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Namíbia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Nicarágua', value: 'NI' },
  { label: 'Níger', value: 'NE' },
  { label: 'Nigéria', value: 'NG' },
  { label: 'Noruega', value: 'NO' },
  { label: 'Nova Zelândia', value: 'NZ' },
  { label: 'Omã', value: 'OM' },
  { label: 'Países Baixos', value: 'NL' },
  { label: 'Palau', value: 'PW' },
  { label: 'Panamá', value: 'PA' },
  { label: 'Papua Nova Guiné', value: 'PG' },
  { label: 'Paquistão', value: 'PK' },
  { label: 'Paraguai', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Polónia', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Quénia', value: 'KE' },
  { label: 'Quirguistão', value: 'KG' },
  { label: 'Quiribáti', value: 'KI' },
  { label: 'Reino Unido', value: 'GB' },
  { label: 'República Centro-Africana', value: 'CF' },
  { label: 'República Checa', value: 'CZ' },
  { label: 'República Democrática do Congo', value: 'CD' },
  { label: 'República Dominicana', value: 'DO' },
  { label: 'Roménia', value: 'RO' },
  { label: 'Ruanda', value: 'RW' },
  { label: 'Rússia', value: 'RU' },
  { label: 'Salomão', value: 'SB' },
  { label: 'Salvador', value: 'SV' },
  { label: 'Samoa', value: 'WS' },
  { label: 'Santa Lúcia', value: 'LC' },
  { label: 'São Cristóvão e Neves', value: 'KN' },
  { label: 'São Marinho', value: 'SM' },
  { label: 'São Tomé e Príncipe', value: 'ST' },
  { label: 'São Vicente e Granadinas', value: 'VC' },
  { label: 'Seicheles', value: 'SC' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serra Leoa', value: 'SL' },
  { label: 'Sérvia', value: 'RS' },
  { label: 'Singapura', value: 'SG' },
  { label: 'Síria', value: 'SY' },
  { label: 'Somália', value: 'SO' },
  { label: 'Sri Lanca', value: 'LK' },
  { label: 'Sudão', value: 'SD' },
  { label: 'Sudão do Sul', value: 'SS' },
  { label: 'Suécia', value: 'SE' },
  { label: 'Suíça', value: 'CH' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Tailândia', value: 'TH' },
  { label: 'Taiuã', value: 'TW' },
  { label: 'Tajiquistão', value: 'TJ' },
  { label: 'Tanzânia', value: 'TZ' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trindade e Tobago', value: 'TT' },
  { label: 'Tunísia', value: 'TN' },
  { label: 'Turcomenistão', value: 'TM' },
  { label: 'Turquia', value: 'TR' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Ucrânia', value: 'UA' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Uruguai', value: 'UY' },
  { label: 'Usbequistão', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Vaticano', value: 'VA' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietname', value: 'VN' },
  { label: 'Zâmbia', value: 'ZM' },
  { label: 'Zimbábue', value: 'ZW' },
];

// const getPais = (code) => {
//   const pp = paisList.find((cenas) => cenas.value == code);
//   if (pp) {
//     return pp.label;
//   } else {
//     return '';
//   }
// };

export const cb = (value) => {
  if (value === true || value === false) {
    return value;
  } else {
    return '';
  }
};
