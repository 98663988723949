import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Cliente, ClienteService, GeneroSexualEnum } from '../../slidein_api';

import { msgAPIError } from '../helpers';
import { MenuContext } from '../../layout/context/MenuContext';
import { homeMenu } from '../Home';

import {
  Badge,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  Group,
  Loader,
  Select,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconAt,
  IconBuildingCommunity,
  IconCake,
  IconDeviceFloppy,
  IconDoor,
  IconFaceId,
  IconFlag,
  IconFlag3,
  IconGenderBigender,
  IconHome2,
  IconNotes,
  IconNumber,
  IconUserEdit,
  IconX,
} from '@tabler/icons-react';
import 'dayjs/locale/pt';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { useBread } from '../../layout/context/BreadContext';
import { MantinePhoneInput } from '../../components/MantinePhoneInput';

export function EditCliente() {
  const { setMenuItems } = useContext(MenuContext);
  const { setBread } = useBread();

  const { clienteID } = useParams();

  const [cliente, setCliente] = useState<Cliente>();

  useEffect(() => {
    if (!cliente) {
      return;
    }
    setMenuItems(homeMenu);
    setBread({
      cliente: { title: cliente.nome_simples, icon: <IconUserEdit /> },
      accao: { title: 'Editar', icon: <IconUserEdit /> },
    });
  }, [cliente]);
  const navigate = useNavigate();

  const today = new Date();

  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - 14);
  const minDate = new Date();
  minDate.setFullYear(today.getFullYear() - 86);

  const [idade, setIdade] = useState<number | undefined>(undefined);

  const [otherErrors, setOtherErrors] = useState(undefined);

  const clienteForm = useForm<Cliente>({
    initialValues: cliente,
    transformValues: (values) => {
      const dn = values.data_nascimento ? new Date(values.data_nascimento) : null;
      const dvi = values.validade_identificacao ? new Date(values.validade_identificacao) : null;

      return {
        ...values,
        data_nascimento: dn
          ? `${dn.getFullYear()}-${(dn.getMonth() + 1).toString().padStart(2, '0')}-${dn
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
        validade_identificacao: dvi
          ? `${dvi.getFullYear()}-${(dvi.getMonth() + 1).toString().padStart(2, '0')}-${dvi
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
      };
    },
  });

  const saveCliente = (cliente: Cliente) => {
    ClienteService.clienteUpdate({ id: cliente.id, requestBody: cliente }).then(
      () => {
        navigate(-1);
      },
      (reason) => {
        clienteForm.setErrors(reason.body);
        setOtherErrors(reason.body['non_field_errors']);
      }
    );
  };

  useEffect(() => {
    ClienteService.clienteRetrieve({ id: parseInt(clienteID) }).then(
      (pvalue) => {
        clienteForm.setValues(pvalue);
        if (pvalue.data_nascimento) {
          setIdade(getAge(dayjs(pvalue.data_nascimento).format('YYYY-MM-DD')));
        }
        setCliente(pvalue);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  }, []);

  useEffect(() => {
    if (clienteForm.values.data_nascimento) {
      setIdade(getAge(dayjs(clienteForm.values.data_nascimento).format('YYYY-MM-DD')));
    }
  }, [clienteForm.values.data_nascimento]);

  const getAge = (birthDateStr: string) => {
    const today = new Date();
    const birthDate = new Date(birthDateStr);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  if (!cliente) {
    return (
      <Center>
        <Loader
          size={'xl'}
          variant={'dots'}></Loader>
      </Center>
    );
  }

  return (
    <Container>
      <form onSubmit={clienteForm.onSubmit(saveCliente)}>
        <Title order={2}>Cliente</Title>
        <Title
          order={3}
          mt={'md'}>
          Credenciais
        </Title>
        <TextInput
          label={'Email'}
          type={'email'}
          icon={<IconAt />}
          {...clienteForm.getInputProps('email')}
        />
        <Checkbox
          mt={'lg'}
          labelPosition='left'
          label='Activo'
          color='green'
          radius='md'
          size='lg'
          {...clienteForm.getInputProps('is_active', { type: 'checkbox' })}
        />
        {otherErrors && <small className='has-text-danger'>{otherErrors}</small>}
        <Title
          order={3}
          mt={'md'}>
          Informação Pessoal
        </Title>
        <Flex
          direction='row'
          gap={'xs'}
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            w={{ base: '100%', xs: '49%' }}
            label={'Nomes Próprios'}
            icon={<IconUserEdit />}
            {...clienteForm.getInputProps('nome_proprio')}
          />
          <TextInput
            w={{ base: '100%', xs: '49%' }}
            label={'Apelidos'}
            icon={<IconUserEdit />}
            {...clienteForm.getInputProps('apelido')}
          />
        </Flex>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <Select
            label={'Género'}
            w={{ base: '100%', xs: '49%' }}
            icon={<IconGenderBigender />}
            data={Object.keys(GeneroSexualEnum).map((x) => {
              return {
                label: GeneroSexualEnum[x as keyof typeof GeneroSexualEnum],
                value: GeneroSexualEnum[x as keyof typeof GeneroSexualEnum],
              };
            })}
            {...clienteForm.getInputProps('genero_sexual')}
          />
          <>
            <DatePickerInput
              w={{ base: '50%', xs: '29%' }}
              label='Data de Nascimento'
              locale={'pt'}
              firstDayOfWeek={1}
              placeholder='Escolher Data'
              maxDate={maxDate}
              minDate={minDate}
              defaultDate={maxDate}
              icon={<IconCake stroke={1.5} />}
              valueFormat='DD/MM/YYYY'
              {...clienteForm.getInputProps('data_nascimento')}
              value={
                clienteForm.values.data_nascimento
                  ? dayjs(clienteForm.values.data_nascimento).toDate()
                  : null
              }
            />
            <Badge
              w={{ base: '50%', xs: '16%' }}
              size='lg'
              mt={'xl'}
              color={idade <= 14 ? 'red' : 'slideinGreen2'}
              radius='md'>
              Idade {idade}
            </Badge>
          </>
        </Flex>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            label={'Nrº Doc. de Identificação (CC, Passaporte,...)'}
            w={{ base: '100%', xs: '49%' }}
            icon={<IconFaceId />}
            {...clienteForm.getInputProps('identificacao')}
          />
          <DatePickerInput
            label='Validade do Documento de Identificação'
            id={'validade_identificacao'}
            w={{ base: '100%', xs: '49%' }}
            locale={'pt'}
            firstDayOfWeek={1}
            placeholder='Escolher Data'
            minDate={new Date()}
            defaultDate={new Date()}
            icon={<IconFaceId stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...clienteForm.getInputProps('validade_identificacao')}
            value={
              clienteForm.values.validade_identificacao
                ? dayjs(clienteForm.values.validade_identificacao).toDate()
                : null
            }
          />
        </Flex>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            label={'NIF-Número de Contribuinte'}
            w={{ base: '100%', xs: '49%' }}
            icon={<IconFaceId />}
            {...clienteForm.getInputProps('nif')}
          />
          <TextInput
            label={'Nacionalidade'}
            w={{ base: '100%', xs: '49%' }}
            icon={<IconFlag3 />}
            {...clienteForm.getInputProps('nacionalidade')}
          />
        </Flex>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <MantinePhoneInput
            w={{ base: '100%', xs: '49%' }}
            label={'Número de Telémovel.'}
            {...clienteForm.getInputProps('contacto_telemovel')}
          />
          <MantinePhoneInput
            label={'Número de Telefone Casa.'}
            w={{ base: '100%', xs: '49%' }}
            {...clienteForm.getInputProps('contacto_casa')}
          />
        </Flex>
        <Title
          order={3}
          mt={'md'}>
          Morada
        </Title>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            w={{ base: '100%', xs: '70%' }}
            label={'Rua/Ave...'}
            autoComplete={'street-address'}
            icon={<IconHome2 />}
            {...clienteForm.getInputProps('morada_rua')}
          />
          <TextInput
            w={{ base: '100%', xs: '29%' }}
            label={'Nrº Porta/ Andar'}
            autoComplete={'address-line1'}
            icon={<IconDoor />}
            {...clienteForm.getInputProps('morada_porta')}
          />
        </Flex>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            label={'Codigo Postal'}
            w={{ base: '100%', xs: '33%' }}
            autoComplete={'postal-code'}
            icon={<IconNumber />}
            {...clienteForm.getInputProps('morada_codigo_postal')}
          />
          <TextInput
            label={'Localidade'}
            w={{ base: '100%', xs: '32%' }}
            icon={<IconBuildingCommunity />}
            {...clienteForm.getInputProps('morada_localidade')}
          />
          <TextInput
            label={'País.'}
            w={{ base: '100%', xs: '32%' }}
            autoComplete={'country-name'}
            icon={<IconFlag />}
            {...clienteForm.getInputProps('morada_pais')}
          />
        </Flex>
        <Title
          order={3}
          mt={'md'}>
          Contactos Urgência/Familia
        </Title>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <MantinePhoneInput
            label={'Número de Contacto Urgência.'}
            w={{ base: '100%', xs: '49%' }}
            {...clienteForm.getInputProps('contacto_urgencia')}
          />
          <TextInput
            w={{ base: '100%', xs: '49%' }}
            label={'Nome do Contacto Urgência.'}
            {...clienteForm.getInputProps('nome_contacto_urgencia')}
          />
          <TextInput
            w={{ base: '100%', xs: '100%%' }}
            label={'Email do Contacto de Urgência'}
            type={'email'}
            icon={<IconFaceId />}
            {...clienteForm.getInputProps('email_contacto_urgencia')}
          />
        </Flex>
        {idade <= 21 && (
          <>
            <TextInput
              label={'Nome completo do Pai.'}
              icon={<IconFaceId />}
              {...clienteForm.getInputProps('nome_pai')}
            />
            <TextInput
              label={'Nome completo do Mãe.'}
              icon={<IconFaceId />}
              {...clienteForm.getInputProps('nome_mae')}
            />
            <TextInput
              label={'Email Pai/Mãe.'}
              type={'email'}
              icon={<IconFaceId />}
              {...clienteForm.getInputProps('email_pais')}
            />
          </>
        )}
        <Textarea
          id={'notas_cliente'}
          name={'notas_cliente'}
          label={'Notas importantes (Doenças, Alergias, etc...):'}
          value={clienteForm.values.notas_cliente}
          {...clienteForm.getInputProps('notas_cliente')}
        />
        <Checkbox
          mt={'lg'}
          labelPosition='left'
          label='Aceitou as condições de privacidade'
          color='green'
          radius='md'
          checked={clienteForm.values.aceitou_condicoes_de_privacidade}
          onChange={() => 1}
          size='lg'
        />
        <Textarea
          placeholder='notas...'
          id={'notas'}
          name={'notas'}
          label='Notas(Admin/Master):'
          size='md'
          icon={<IconNotes />}
          autosize
          minRows={2}
          {...clienteForm.getInputProps('notas')}
        />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button
            type='submit'
            variant={'outline'}
            leftIcon={<IconDeviceFloppy />}>
            Guardar Alterações
          </Button>
        </Group>
      </form>
    </Container>
  );
}
