import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MenuContext } from '../../layout/context/MenuContext';
import { homeMenu } from '../Home';
import { Link, useNavigate } from 'react-router-dom';
import { ApiError, Cliente, ClienteService, OpenAPI } from '../../slidein_api';
import { useBread } from '../../layout/context/BreadContext';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_GlobalFilterTextInput,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { cb, iconBool, mrtSortingToAPI, msgAPIError } from '../helpers';
import { ActionIcon, Box, Button, Flex, LoadingOverlay, Stack, Text, Tooltip } from '@mantine/core';
import {
  IconDownload,
  IconEdit,
  IconFileSpreadsheet,
  IconTrash,
  IconTrashX,
  IconUserCancel,
  IconUserOff,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import FileSaver from 'file-saver';

export function ClienteSlideIn() {
  const { setMenuItems } = useContext(MenuContext);
  const { setBread } = useBread();
  useEffect(() => {
    setMenuItems(homeMenu);
    setBread({ accao: { title: 'Utilizadores (Geral)', icon: <IconUsersGroup /> } });
  }, []);

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [clientes, setClientes] = useState<Cliente[]>([]);

  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  function paramsNormalize(columnFilters: MRT_ColumnFiltersState) {
    const params = {};
    columnFilters.forEach((c) => (params[c.id] = c.value));
    if (params['dataNascimento']) {
      const da = params['dataNascimento'][0];
      const db = params['dataNascimento'][1];

      params['dataNascimentoAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['dataNascimentoBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['validadeIdentificacao']) {
      const da = params['validadeIdentificacao'][0];
      const db = params['validadeIdentificacao'][1];

      params['validadeIdentificacaoAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['validadeIdentificacaoBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    return params;
  }

  const tToslideAPI: Record<string, string> = {
    aceitouCondicoesDePrivacidade: 'aceitou_condicoes_de_privacidade',
    apelido: 'apelido',
    contactoCasa: 'contacto_casa',
    contactoTelemovel: 'contacto_telemovel',
    contactoUrgencia: 'contacto_urgencia',
    dataNascimento: 'data_nascimento',
    email: 'email',
    emailContactoUrgencia: 'email_contacto_urgencia',
    emailPais: 'email_pais',
    gdprAnomAplied: 'gdpr_anom_aplied',
    generoSexual: 'genero_sexual',
    identificacao: 'identificacao',
    isActive: 'is_active',
    moradaCodigoPostal: 'morada_codigo_postal',
    moradaLocalidade: 'morada_localidade',
    moradaPais: 'morada_pais',
    moradaPorta: 'morada_porta',
    moradaRua: 'morada_rua',
    nacionalidade: 'nacionalidade',
    nif: 'nif',
    nomeCompleto: 'nome_completo',
    nomeContactoUrgencia: 'nome_contacto_urgencia',
    nomeMae: 'nome_mae',
    nomePai: 'nome_pai',
    nomeProprio: 'nome_proprio',
    nomeSimples: 'nome_simples',
    validadeIdentificacao: 'validade_identificacao',
  };

  const fetchData = (page: number, pageSize: number) => {
    setIsRefetching(true);
    setLoading(true);
    ClienteService.clienteList({
      ...paramsNormalize(columnFilters),
      page: page + 1,
      pageSize: pageSize,
      search: globalFilter ?? '',
      ordering: mrtSortingToAPI(sorting, tToslideAPI),
    }).then(
      (value) => {
        setClientes(value.results);
        setRowCount(value.count);
        setIsRefetching(false);
        setLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };

  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize);
  }, [pagination, sorting]);

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 15 });
    fetchData(pagination.pageIndex, pagination.pageSize);
  }, [columnFilters, globalFilter]);

  const columns = useMemo<MRT_ColumnDef<Cliente>[]>(
    () => [
      {
        accessorFn: (row) => iconBool(row.is_active),
        header: 'Activo',
        id: 'isActive',
        size: 20,
        filterVariant: 'checkbox',
      },
      {
        accessorFn: (row) => iconBool(row.aceitou_condicoes_de_privacidade),
        header: 'Aceitou Cond. Priv.',
        id: 'aceitouCondicoesDeProvacidade',
        filterVariant: 'checkbox',
      },
      { accessorKey: 'nome_completo', header: 'Nome', id: 'nomeCompleto', filterVariant: 'text' },
      {
        accessorFn: (row) => <a href={'mailto:' + row.email}>{row.email}</a>,
        header: 'Email',
        id: 'email',
        filterVariant: 'text',
      },
      {
        accessorKey: 'contacto_telemovel',
        header: 'Telemovel',
        id: 'contactoTelemovel',
        filterVariant: 'text',
      },

      {
        accessorKey: 'data_nascimento',
        header: 'Data Nascimento',
        id: 'dataNascimento',
        filterVariant: 'date-range',
      },
      { accessorKey: 'identificacao', header: 'ID', id: 'identificacao', filterVariant: 'text' },
      {
        accessorKey: 'validade_identificacao',
        header: 'Validade ID',
        id: 'validadeIdentificacao',
        filterVariant: 'date-range',
      },
      { accessorKey: 'genero_sexual', header: 'Género', id: 'generoSexual', filterVariant: 'text' },
      { accessorKey: 'nif', header: 'NIF', id: 'nif', filterVariant: 'text' },

      {
        accessorKey: 'nacionalidade',
        header: 'Nacionalidade',
        id: 'nacionalidade',
        filterVariant: 'text',
      },
      {
        accessorKey: 'contacto_casa',
        header: 'Telefone casa',
        id: 'contactoCasa',
        filterVariant: 'text',
      },
      { accessorKey: 'morada_rua', header: 'Rua', id: 'moradaRua', filterVariant: 'text' },
      { accessorKey: 'morada_porta', header: 'Porta', id: 'moradaPorta', filterVariant: 'text' },
      {
        accessorKey: 'morada_localidade',
        header: 'Localidade',
        id: 'moradaLocalidade',
        filterVariant: 'text',
      },
      {
        accessorKey: 'morada_codigo_postal',
        header: 'Codigo Postal',
        id: 'moradaCodigoPostal',
        filterVariant: 'text',
      },
      { accessorKey: 'morada_pais', header: 'Pais', id: 'moradaPais', filterVariant: 'text' },
      {
        accessorKey: 'nome_contacto_urgencia',
        header: 'Nome urgência',
        id: 'nomeContactoUrgencia',
        filterVariant: 'text',
      },
      {
        accessorKey: 'contacto_urgencia',
        header: 'Telefone Urgência',
        id: 'contactoUrgencia',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (
          <a href={'mailto:' + row.email_contacto_urgencia}>{row.email_contacto_urgencia}</a>
        ),
        header: 'Email Urgência',
        id: 'emailContactoUrgencia',
        filterVariant: 'text',
      },
      { accessorKey: 'nome_pai', header: 'Nome Pai', id: 'nomePai', filterVariant: 'text' },
      { accessorKey: 'nome_mae', header: 'Nome Mãe', id: 'nomeMae', filterVariant: 'text' },
      {
        accessorFn: (row) => <a href={'mailto:' + row.email_pais}>{row.email_pais}</a>,
        header: 'Email Pai/Mãe',
        id: 'emailPais',
        filterVariant: 'text',
      },

      {
        accessorFn: (row) => iconBool(row.gdpr_anom_aplied),
        header: 'GDPR',
        id: 'gdprAnomAplided',
        filterVariant: 'checkbox',
      },
      {
        accessorFn: (row) => {
          return (
            <Stack>
              {(row.viagens || []).map((vi) => (
                <Link
                  key={vi.id}
                  to={'/campanha/' + vi.campanha.identificador + '/viagem/' + vi.id}>
                  {vi.campanha.nome}({vi.esta_paga ? 'Paga' : 'Não Paga'})
                </Link>
              ))}
            </Stack>
          );
        },
        header: 'Viagens',
        id: 'viagens',
        enableColumnFilter: false,
      },
    ],
    []
  );

  const clientTable = useMantineReactTable({
    data: clientes,
    columns,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Tooltip label='Editar'>
          <ActionIcon onClick={() => navigate(`${row.original.id}`)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Apagar dados Pessoais(GDPR...)'>
          <ActionIcon
            onClick={() =>
              modals.openConfirmModal({
                title: 'Aplicar GDPR Anonimização',
                centered: true,
                children: (
                  <Text size='sm'>
                    Confirma que deseja apagar os dados pessoais do utilizador (ACÇÃO IRREVERSÍVEL
                    !!!) : {row.original.nome_simples} ?
                  </Text>
                ),
                labels: { confirm: 'Aplicar', cancel: 'Cancelar' },
                confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconUserOff /> },
                cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                onConfirm: () => {
                  ClienteService.clienteGdprUserUpdate({
                    id: row.original.id,
                    requestBody: row.original,
                  }).then(
                    () => {
                      fetchData(pagination.pageIndex, pagination.pageSize);
                      notifications.show({
                        title: 'GDPR Aplicado!',
                        message: `O Utilizador foi esquecido.`,
                        withCloseButton: true,
                        icon: <IconTrashX />,
                        color: 'green',
                      });
                    },
                    (reason: ApiError) => {
                      msgAPIError(reason);
                    }
                  );
                },
              })
            }>
            <IconUserCancel />
          </ActionIcon>
        </Tooltip>
        {row.original.viagens.length === 0 && (
          <Tooltip label='Remover conta'>
            <ActionIcon
              onClick={() => {
                modals.openConfirmModal({
                  title: 'Remover utilizador',
                  centered: true,
                  children: (
                    <Text size='sm'>
                      Confirma que deseja remover o Utilizador : {row.original.nome_simples} ?
                    </Text>
                  ),
                  labels: { confirm: 'Remover', cancel: 'Cancelar' },
                  confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                  cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                  onConfirm: () => {
                    ClienteService.clienteDestroy({ id: row.original.id }).then(
                      () => {
                        fetchData(pagination.pageIndex, pagination.pageSize);
                        notifications.show({
                          title: 'Utilizador removido!',
                          message: `O Utilizador ${row.original.nome_simples} foi removido.`,
                          withCloseButton: true,
                          icon: <IconTrashX />,
                          color: 'green',
                        });
                      },
                      (reason: ApiError) => {
                        if (reason.status == 423) {
                          notifications.show({
                            title: 'Utilizador!',
                            message: `O Utilizador  ${row.original.nome_simples} NÃO foi removido, existem viagens associadas!`,
                            withCloseButton: true,
                            icon: <IconX />,
                            color: 'yellow',
                            autoClose: 5000,
                          });
                        } else {
                          msgAPIError(reason);
                        }
                      }
                    );
                  },
                });
              }}>
              <IconUserCancel />
            </ActionIcon>
          </Tooltip>
        )}
      </Box>
    ),
    positionActionsColumn: 'first',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    enableColumnOrdering: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      showProgressBars: isRefetching,
      sorting,
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
      columnVisibility: {
        activo: true,
        nomeCompleto: true,
        email: true,
        contactoTelemovel: true,
        dataNascimento: true,
        generoSexual: false,
        nif: false,
        identificacao: false,
        validadeIdentificacao: false,
        nacionalidade: false,
        contactoCasa: false,
        moradaRua: false,
        moradaPorta: false,
        moradaCodigoPostal: false,
        moradaLocalidade: false,
        moradaPais: false,
        nomeContactoUrgencia: false,
        contactoUrgencia: true,
        emailUrgencia: false,
        nomePai: false,
        nomeMae: false,
        emailPais: false,
        aceitouCondicoesDeProvacidade: false,
        gdprAnomAplided: false,
        viagens: true,
      },
    },

    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    globalFilterFn: 'contains',
    enableStickyHeader: true,
    positionGlobalFilter: 'none',
    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'space-evenly'}>
        <MRT_GlobalFilterTextInput table={table} />

        <Button
          color='lightblue'
          onClick={() => {
            setLoading(true);
            const cenas: {
              aceitouCondicoesDePrivacidade?: boolean;
              apelido?: string;
              contactoCasa?: string;
              contactoTelemovel?: string;
              contactoUrgencia?: string;
              creationDateAfter?: string;
              creationDateBefore?: string;
              dataNascimentoAfter?: string;
              dataNascimentoBefore?: string;
              email?: string;
              emailContactoUrgencia?: string;
              emailPais?: string;
              gdprAnomAplied?: boolean;
              generoSexual?: string;
              identificacao?: string;
              isActive?: boolean;
              lastLoginAfter?: string;
              lastLoginBefore?: string;
              moradaCodigoPostal?: string;
              moradaLocalidade?: string;
              moradaPais?: string;
              moradaPorta?: string;
              moradaRua?: string;
              nacionalidade?: string;
              nif?: string;
              nomeCompleto?: string;
              nomeContactoUrgencia?: string;
              nomeMae?: string;
              nomePai?: string;
              nomeProprio?: string;
              nomeSimples?: string;
              search?: string;
              ordering?: string;
              validadeIdentificacaoAfter?: string;
              validadeIdentificacaoBefore?: string;
            } = {
              ...paramsNormalize(columnFilters),
              search: globalFilter ?? '',
              ordering: mrtSortingToAPI(sorting, tToslideAPI),
            };
            const url = `${OpenAPI.BASE}/gestao/cliente/mapa`;
            axios
              .get(url, {
                responseType: 'blob',
                params: {
                  aceitou_condicoes_de_privacidade: cb(cenas.aceitouCondicoesDePrivacidade),
                  apelido: cenas.apelido || '',
                  contacto_casa: cenas.contactoCasa || '',
                  contacto_telemovel: cenas.contactoTelemovel || '',
                  contacto_urgencia: cenas.contactoUrgencia || '',
                  creation_date_after: cenas.creationDateAfter || '',
                  creation_date_before: cenas.creationDateBefore || '',
                  data_nascimento_after: cenas.dataNascimentoAfter || '',
                  data_nascimento_before: cenas.dataNascimentoBefore || '',
                  email: cenas.email || '',
                  email_contacto_urgencia: cenas.emailContactoUrgencia || '',
                  email_pais: cenas.emailPais || '',
                  gdpr_anom_aplied: cb(cenas.gdprAnomAplied),
                  genero_sexual: cenas.generoSexual || '',
                  identificacao: cenas.identificacao || '',
                  is_active: cb(cenas.isActive),
                  last_login_after: cenas.lastLoginAfter || '',
                  last_login_before: cenas.lastLoginBefore || '',
                  morada_codigo_postal: cenas.moradaCodigoPostal || '',
                  morada_localidade: cenas.moradaLocalidade || '',
                  morada_pais: cenas.moradaPais || '',
                  morada_porta: cenas.moradaPorta || '',
                  morada_rua: cenas.moradaRua || '',
                  nacionalidade: cenas.nacionalidade || '',
                  nif: cenas.nif || '',
                  nome_completo: cenas.nomeCompleto || '',
                  nome_contacto_urgencia: cenas.nomeContactoUrgencia || '',
                  nome_mae: cenas.nomeMae || '',
                  nome_pai: cenas.nomePai || '',
                  nome_proprio: cenas.nomeProprio || '',
                  nome_simples: cenas.nomeSimples || '',
                  ordering: cenas.ordering || '',
                  search: cenas.search || '',
                  validade_identificacao_after: cenas.validadeIdentificacaoAfter || '',
                  validade_identificacao_before: cenas.validadeIdentificacaoBefore || '',
                },
              })
              .then(
                (res) => {
                  const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                  });
                  FileSaver.saveAs(blob, 'utilizadores.xlsx');
                  setLoading(false);
                  return res;
                },
                (reason) => {
                  msgAPIError(reason);
                  setLoading(false);
                }
              );
          }}
          rightIcon={<IconFileSpreadsheet />}
          leftIcon={<IconDownload />}
          variant='outline'>
          .xlsx
        </Button>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
      </Flex>
    ),
  });

  return (
    <Stack>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}
      />
      <MantineReactTable table={clientTable} />
    </Stack>
  );
}
