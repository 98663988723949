import { ApiError, Colaborador, ColaboradorService, OpenAPI } from '../../slidein_api';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MenuContext } from '../../layout/context/MenuContext';
import { homeMenu } from '../Home';
import { Link, useNavigate } from 'react-router-dom';
import {
  ActionIcon,
  Box,
  Button,
  CopyButton,
  Flex,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconCheck,
  IconCopy,
  IconDownload,
  IconEdit,
  IconFileSpreadsheet,
  IconPlus,
  IconTrash,
  IconTrashX,
  IconUserCancel,
  IconUserOff,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { iconBool, msgAPIError } from '../helpers';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { notifications } from '@mantine/notifications';
import { useBread } from '../../layout/context/BreadContext';
import { modals } from '@mantine/modals';
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import axios from 'axios';
import FileSaver from 'file-saver';

export function ColaboradorSlideIn() {
  const { setMenuItems } = useContext(MenuContext);
  const { setBread } = useBread();
  useEffect(() => {
    setMenuItems(homeMenu);
    setBread({ accao: { title: 'Colaboradores', icon: <IconUsersGroup /> } });
  }, []);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [colaboradores, setColaboradores] = useState<Colaborador[]>([]);

  const fetchData = () => {
    setIsLoading(true);
    ColaboradorService.colaboradorList({}).then(
      (value) => {
        setColaboradores(value);
        setIsLoading(false);
      },
      (reason) => {
        setIsLoading(false);
        msgAPIError(reason);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<Colaborador>[]>(
    () => [
      { accessorKey: 'nome_completo', header: 'Nome', filterVariant: 'text' },
      {
        accessorKey: 'slidein_role',
        header: 'Papel(Auth)',
        filterVariant: 'text',
      },
      {
        accessorKey: 'slidein_funcao',
        header: 'Função',
        filterVariant: 'text',
      },

      {
        accessorFn: (row) => <a href={'mailto:' + row.email}>{row.email}</a>,
        id: 'email',
        header: 'Email',
        filterVariant: 'text',
      },
      {
        accessorKey: 'contacto_telemovel',
        header: 'Telemovel',
        filterVariant: 'text',
      },
      {
        accessorKey: 'is_active',
        header: 'Activo',
        id: 'is_active',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.getValue()),
      },
      {
        accessorKey: 'data_nascimento',
        header: 'Data Nascimento',
        filterVariant: 'date-range',
      },
      { accessorKey: 'genero_sexual', header: 'Género', filterVariant: 'text' },
      { accessorKey: 'nif', header: 'NIF', filterVariant: 'text' },
      { accessorKey: 'identificacao', header: 'ID', filterVariant: 'text' },
      {
        accessorKey: 'validade_identificacao',
        header: 'Validade ID',
        filterVariant: 'date-range',
      },
      {
        accessorKey: 'nacionalidade',
        header: 'Nacionalidade',
        filterVariant: 'text',
      },
      {
        accessorKey: 'contacto_casa',
        header: 'Telefone casa',
        filterVariant: 'text',
      },
      { accessorKey: 'morada_rua', header: 'Rua', filterVariant: 'text' },
      { accessorKey: 'morada_porta', header: 'Porta', filterVariant: 'text' },
      {
        accessorKey: 'morada_localidade',
        header: 'Localidade',
        filterVariant: 'text',
      },
      {
        accessorKey: 'morada_codigo_postal',
        header: 'Codigo Postal',
        filterVariant: 'text',
      },
      { accessorKey: 'morada_pais', header: 'Pais', filterVariant: 'text' },
      {
        accessorKey: 'nome_contacto_urgencia',
        header: 'Nome urgência',
        filterVariant: 'text',
      },
      {
        accessorKey: 'contacto_urgencia',
        header: 'Telefone Urgência',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (
          <a href={'mailto:' + row.email_contacto_urgencia}>{row.email_contacto_urgencia}</a>
        ),
        id: 'email_contacto_urgencia',
        header: 'Email Urgência',
        filterVariant: 'text',
      },
      {
        accessorFn: (originalRow) => (originalRow.gdpr_anom_aplied ? 'true' : 'false'),
        header: 'GDPR',
        id: 'gdpr_anom_aplied',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.getValue()),
      },
    ],
    []
  );

  const colabTable = useMantineReactTable({
    data: colaboradores,
    columns,
    positionGlobalFilter: 'none',
    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'space-evenly'}>
        <MRT_GlobalFilterTextInput table={table} />
        <Button
          leftIcon={<IconPlus />}
          variant='outline'
          onClick={() => {
            navigate('new');
          }}>
          Adicionar Colaborador
        </Button>

        <Button
          color='lightblue'
          onClick={() => {
            const url = `${OpenAPI.BASE}/gestao/colaborador/mapa`;
            axios
              .get(url, {
                responseType: 'blob',
                params: {},
              })
              .then(
                (res) => {
                  const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                  });
                  FileSaver.saveAs(blob, 'colaborador.xlsx');
                  return res;
                },
                (reason) => {
                  msgAPIError(reason);
                }
              );
          }}
          rightIcon={<IconFileSpreadsheet />}
          leftIcon={<IconDownload />}
          variant='outline'>
          .xlsx
        </Button>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
      </Flex>
    ),
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: {
        pageSize: 15,
        pageIndex: 0,
      },
      columnVisibility: {
        nomeCompleto: true,
        slideinRole: true,
        slideinFuncao: true,
        email: true,
        contactoTelemovel: true,
        activo: false,
        dataNascimento: true,
        generoSexual: false,
        nif: false,
        identificacao: false,
        validadeIdentificacao: false,
        nacionalidade: false,
        contactoCasa: false,
        moradaRua: false,
        moradaPorta: false,
        moradaCodigoPostal: false,
        moradaLocalidade: false,
        moradaPais: false,
        nomeContactoUrgencia: false,
        contactoUrgencia: true,
        emailUrgencia: false,
        nomePai: false,
        nomeMae: false,
        emailPais: false,
        aceitouCondicoesDeProvacidade: false,
        gdprAnomAplided: false,
        viagens: true,
      },
    },
    state: {
      isLoading,
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Tooltip label='Editar'>
          <ActionIcon
            color='blue'
            onClick={() => navigate(`${row.original.id}`)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Remover'>
          <ActionIcon
            color='red'
            onClick={() =>
              modals.openConfirmModal({
                title: 'Remover Colaborador',
                centered: true,
                children: (
                  <Text size='sm'>
                    Confirma que deseja remover a Colaboradoor : {row.original.nome_simples} ?
                  </Text>
                ),
                labels: { confirm: 'Remover', cancel: 'Cancelar' },
                confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                onConfirm: () => {
                  ColaboradorService.colaboradorDestroy({ id: row.original.id }).then(
                    () => {
                      setColaboradores(colaboradores.filter((obj) => obj.id !== row.original.id));
                      notifications.show({
                        title: 'Colaborador removido!',
                        message: `O colaborador ${row.original.nome_simples} foi removido.`,
                        withCloseButton: true,
                        icon: <IconTrashX />,
                        color: 'green',
                      });
                    },
                    (reason: ApiError) => {
                      if (reason.status == 423) {
                        notifications.show({
                          title: 'Instituição!',
                          message: `O colaborador ${row.original.nome_simples} NÃO foi removido, existem grupos/campanhas associados!`,
                          withCloseButton: true,
                          icon: <IconX />,
                          color: 'yellow',
                          autoClose: 5000,
                        });
                      } else {
                        msgAPIError(reason);
                      }
                    }
                  );
                },
              })
            }>
            <IconTrashX />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Apagar dados Pessoais(GDPR...)'>
          <ActionIcon
            onClick={() =>
              modals.openConfirmModal({
                title: 'Aplicar GDPR Anonimização',
                centered: true,
                children: (
                  <Text size='sm'>
                    Confirma que deseja apagar os dados pessoais do utilizador (ACÇÃO IRREVERSÍVEL
                    !!!) : {row.original.nome_simples} ?
                  </Text>
                ),
                labels: { confirm: 'Aplicar', cancel: 'Cancelar' },
                confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconUserOff /> },
                cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                onConfirm: () => {
                  ColaboradorService.colaboradorGdprUserUpdate({
                    id: row.original.id,
                    requestBody: row.original,
                  }).then(
                    () => {
                      fetchData();
                      notifications.show({
                        title: 'GDPR Aplicado!',
                        message: `O Colaborador foi foi esquecido.`,
                        withCloseButton: true,
                        icon: <IconTrashX />,
                        color: 'green',
                      });
                    },
                    (reason: ApiError) => {
                      msgAPIError(reason);
                    }
                  );
                },
              })
            }>
            <IconUserCancel />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),
    positionActionsColumn: 'first',
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
  });
  const reg_url = `${window.location.origin}/registar_staff`;

  return (
    <Stack>
      <Flex
        direction={'column'}
        gap={'xs'}
        wrap={'wrap'}
        align={'start'}>
        <Flex
          direction={'row'}
          gap={'xs'}
          wrap={'wrap'}
          justify={'space-between'}>
          <Title order={3}>Link para Registar Staff</Title>
          <Flex
            direction={'row'}
            justify={'space-between'}
            gap={'xs'}>
            <WhatsappShareButton
              url={reg_url}
              title={'SLIDEIN TRAVEL : Registar como staff'}
              separator={'\n'}>
              <WhatsappIcon
                className={'m-1'}
                size={'30'}
                borderRadius={10}
              />
            </WhatsappShareButton>
            <TelegramShareButton
              url={reg_url}
              title={'SLIDEIN TRAVEL : Registar como staff'}>
              <TelegramIcon
                className={'m-1'}
                size={'30'}
                borderRadius={10}
              />
            </TelegramShareButton>
            <EmailShareButton
              url={reg_url}
              subject={'SLIDEIN TRAVEL : Registar como staff'}
              body={'Olá\n ' + 'Este é o link para te registares na Plataforma.'}
              separator={'\n'}>
              <EmailIcon
                className={'m-1'}
                size={'30'}
                borderRadius={10}
              />
            </EmailShareButton>
          </Flex>
        </Flex>

        <Flex
          direction={'row'}
          justify={'start'}
          wrap={'nowrap'}
          gap={'xs'}>
          <Link
            to={'/registar_staff'}
            target={'_blank'}
            rel='noopener noreferrer'>
            {reg_url}
          </Link>
          <CopyButton
            value={reg_url}
            timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? 'Copied' : 'Copy'}
                withArrow
                position='right'>
                <ActionIcon
                  color={copied ? 'teal' : 'gray'}
                  onClick={copy}>
                  {copied ? <IconCheck size='1rem' /> : <IconCopy size='1rem' />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Flex>
      </Flex>

      <MantineReactTable table={colabTable} />
    </Stack>
  );
}
