import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ApiError,
  CompraService,
  ComprasViagem,
  GrupoItem,
  GrupoService,
  ViagemService,
} from '../../../../slidein_api';
import { useCampanha } from '../../CampanhaWrapperView';
import { msgAPIError } from '../../../helpers';
import {
  ActionIcon,
  Alert,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Stack,
  Switch,
  Table,
  Tabs,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconAlertCircle,
  IconAt,
  IconCalculator,
  IconCell,
  IconCheck,
  IconCreditCard,
  IconExclamationCircle,
  IconHomeMove,
  IconInfoCircle,
  IconMedicalCross,
  IconNotes,
  IconPdf,
  IconPencil,
  IconPlane,
  IconReceipt2,
  IconRulerMeasure,
  IconTrash,
  IconTrashX,
  IconUser,
  IconUserEdit,
  IconUserHeart,
  IconUserOff,
  IconUsers,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import { useBread } from '../../../../layout/context/BreadContext';
import { ComprasViagemView } from './ComprasViagemView';
import { PagamentosViagem } from './PagamentosViagem';
import { RefMultibancoView } from './RefMultibancoView';
import { ViagemFiles } from './ViagemFiles';
import { notifications } from '@mantine/notifications';
import { PrestacoesViagem } from './PrestacoesViagem';
import { modals } from '@mantine/modals';
import { ViagemNotas } from './ViagemNotas';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { ViagemLogi } from './ViagemInfoLogi';

function getRows(table) {
  if (table?.getSelectedRowModel() && table.getSelectedRowModel().rows) {
    return table.getSelectedRowModel().rows;
  } else {
    return [];
  }
}

export function ViagemView() {
  const { campanhaID } = useParams();
  const { campanha } = useCampanha();
  const params = useParams();
  const navigate = useNavigate();
  const { setBread } = useBread();
  const ref = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [viagem, setViagem] = useState<ComprasViagem>(null);
  const [grupos, setGrupos] = useState<GrupoItem[]>([]);
  const [moveGrupoVisible, setMoveGrupoVisible] = useState<boolean>(false);

  const fetchData = () => {
    setLoading(true);
    GrupoService.grupoItemList({ campanha: campanhaID }).then((value) => {
      setGrupos(value);
    });
    CompraService.compraViagemRetrieve({ id: parseInt(params.viagemID) }).then(
      (value: ComprasViagem) => {
        setViagem(value);
        setBread({
          campanha: {
            title: campanhaID,
            href: '/campanha/' + campanhaID,
            icon: <IconPlane />,
          },
          grupo: {
            title: `${value.grupo.codigo}(${value.instituicao})`,
            href: '/campanha/' + campanhaID + '/grupo/' + value.grupo.codigo,
            icon: <IconUsersGroup />,
          },
          cliente: {
            title: value.cliente.nome_simples,
            icon: <IconUser />,
          },
        });

        setLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    fetchData();
  }, []);
  const columnsGrupos = useMemo<MRT_ColumnDef<GrupoItem>[]>(
    () => [
      {
        accessorKey: 'codigo',
        header: 'Grupo',
      },
      {
        accessorKey: 'instituicao',
        header: 'Instituiçãoo',
        size: 100,
      },
    ],
    []
  );
  const gruposTable = useMantineReactTable({
    columns: columnsGrupos,
    data: grupos || [],
    enableRowSelection: (row) => viagem?.grupo.codigo != row.original.codigo,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity: viagem?.grupo.codigo != row.original.codigo ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: 'none',
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableFilters: true,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
    },
  });

  return (
    <Stack>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}
      />
      {viagem?.cancelada && (
        <Flex direction={'row'}>
          <Alert
            w={'100%'}
            icon={<IconAlertCircle size='1rem' />}
            color='red'>
            Esta viagem está Cancelada!!!
          </Alert>
        </Flex>
      )}
      {viagem?.bloqueada_pagamentos && (
        <Flex direction={'row'}>
          <Alert
            w={'100%'}
            icon={<IconAlertCircle size='1rem' />}
            color='red'>
            Esta viagem está bloqueada por falta de Pagamentos!!!
          </Alert>
        </Flex>
      )}
      {viagem?.cancel_info && (
        <Flex direction={'row'}>
          <Alert
            w={'100%'}
            icon={<IconInfoCircle size='1rem' />}
            color='gray'>
            {viagem.cancel_info}
            <i></i>
          </Alert>
        </Flex>
      )}
      {viagem?.bloqueio_info && (
        <Flex direction={'row'}>
          <Alert
            w={'100%'}
            icon={<IconInfoCircle size='1rem' />}
            color='gray'>
            {viagem.bloqueio_info}
            <i></i>
          </Alert>
        </Flex>
      )}
      <Grid grow>
        <Grid.Col span={'auto'}>
          <Table
            verticalSpacing='xs'
            w={'100%'}>
            <tbody>
              <tr>
                <td>
                  <IconUser />
                  <b>Cliente:</b>
                </td>
                <td>
                  <Flex
                    direction={'row'}
                    gap={'xs'}>
                    <Text
                      fz={'xl'}
                      fw={'bold'}>
                      {viagem?.cliente.nome_simples}
                    </Text>
                    {viagem?.chefe_de_grupo && <span> (Chefe de Grupo)</span>}
                    <Tooltip label={'Editar Informação do Cliente'}>
                      <ActionIcon
                        onClick={() => navigate(`/cliente/${viagem?.cliente.id}`)}
                        variant='outline'
                        color={'yellow'}>
                        <IconUserEdit />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                </td>
              </tr>
              <tr>
                <td>
                  <IconAt size={'1rem'} />
                  <b>email:</b>
                </td>
                <td>
                  <a href={viagem?.cliente.email}>{viagem?.cliente.email}</a>
                </td>
              </tr>
              <tr>
                <td>
                  <IconCell size={'1rem'} />
                  <b>Telemóvel</b>
                </td>
                <td>{viagem?.cliente.contacto_telemovel}</td>
              </tr>
              <tr>
                <td align={'center'}>
                  <IconMedicalCross
                    size={'1rem'}
                    color={'red'}
                  />
                  Urgência
                </td>
                <td>
                  {viagem?.cliente.nome_contacto_urgencia}
                  <br />
                  {viagem?.cliente.contacto_urgencia}
                  <br />
                  <a href={viagem?.cliente.email_contacto_urgencia}>
                    {viagem?.cliente.email_contacto_urgencia}
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid.Col>

        <Grid.Col span={'auto'}>
          <Table
            verticalSpacing={'xs'}
            w={'100%'}>
            <tbody>
              <tr>
                <td>Quarto Atribuido:</td>
                <td>{viagem?.quarto ? viagem?.quarto.nome : 'Não'}</td>
              </tr>
              <tr>
                <td>Transporte Atribuido:</td>
                <td>{viagem?.transporte ? viagem?.transporte : 'Não'}</td>
              </tr>
              <tr>
                <td>Prestações em atraso:</td>
                <td>{viagem?.prestacoes_em_atraso ? 'Sim' : 'Não'}</td>
              </tr>
              <tr>
                <td>
                  <Text fw={'bold'}>Nrº Factura:</Text>
                </td>
                <td>
                  <Group>
                    {viagem?.nr_factura || '--'}
                    <ActionIcon
                      color={'yellow'}
                      onClick={() => {
                        modals.open({
                          title: 'Número de Factura',
                          children: (
                            <>
                              <TextInput
                                placeholder='--'
                                data-autofocus
                                defaultValue={viagem.nr_factura}
                                ref={ref}></TextInput>
                              <Group position={'right'}>
                                <Button
                                  variant={'outline'}
                                  color={'yellow'}
                                  onClick={modals.closeAll}
                                  mt='md'>
                                  Cancelar
                                </Button>
                                <Button
                                  variant={'outline'}
                                  onClick={() => {
                                    setLoading(true);
                                    ViagemService.viagemPartialUpdate({
                                      id: viagem?.id,
                                      requestBody: {
                                        nr_factura: ref.current.value,
                                      },
                                    }).then(
                                      () => {
                                        setLoading(false);
                                        fetchData();
                                        modals.closeAll();
                                      },
                                      (reason) => {
                                        msgAPIError(reason);
                                        setLoading(false);
                                        modals.closeAll();
                                      }
                                    );
                                  }}
                                  mt='md'>
                                  Guardar
                                </Button>
                              </Group>
                            </>
                          ),
                        });
                      }}>
                      <IconPencil />
                    </ActionIcon>
                    {/*<Switch*/}
                    {/*  disabled={viagem?.cancelada}*/}
                    {/*  label={'Viagem Facturada'}*/}
                    {/*  size={'sm'}*/}
                    {/*  onLabel={<IconCheck />}*/}
                    {/*  offLabel={<IconX />}*/}
                    {/*  checked={viagem?.nr_factura}*/}
                    {/*  onChange={(event) => {*/}

                    {/*  }}></Switch>*/}
                  </Group>
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid.Col>
        <Grid.Col span={'auto'}>
          <Table
            verticalSpacing={'xs'}
            w={'100%'}>
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  align={'center'}>
                  Valores:
                </td>
              </tr>

              <tr>
                <td>Total</td>
                <td>{viagem?.valor_total}€</td>
              </tr>
              <tr>
                <td>Valor Pago</td>
                <td>{viagem?.valor_pago}€</td>
              </tr>
              <tr>
                <td>Valor Devido</td>
                <td>{viagem?.valor_devido}€</td>
              </tr>
            </tbody>
          </Table>
        </Grid.Col>
        <Grid.Col span={'auto'}>
          <Flex
            styles={{ flexGrow: 1, flexBasis: 'auto' }}
            direction={'column'}
            gap={'xs'}>
            <Group>
              {!viagem?.cancelada && (
                <Button
                  variant={'outline'}
                  color={'yellow'}
                  leftIcon={<IconExclamationCircle />}
                  onClick={() => {
                    modals.openConfirmModal({
                      title: 'Cancelar Viagem!',
                      centered: true,
                      children: (
                        <Text size='sm'>
                          Confirma que deseja cancelar a Viagem : {viagem?.cliente.nome_simples} ?
                        </Text>
                      ),
                      labels: { confirm: 'Cancelar Viagem', cancel: 'Sair' },
                      confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconUserOff /> },
                      cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                      onConfirm: () => {
                        ViagemService.viagemCancelViagemRetrieve({ id: viagem?.id }).then(
                          () => {
                            fetchData();
                            notifications.show({
                              title: 'Cancelamento com sucesso ',
                              message: `A Viagem ${viagem?.cliente.nome_simples} foi cancelada.`,
                              withCloseButton: true,
                              icon: <IconTrashX />,
                              color: 'green',
                            });
                          },
                          (reason: ApiError) => {
                            msgAPIError(reason);
                          }
                        );
                      },
                    });
                  }}>
                  Cancelar Viagem
                </Button>
              )}
              {viagem?.cancelada && (
                <Button
                  variant={'outline'}
                  color={'green'}
                  leftIcon={<IconExclamationCircle />}
                  onClick={() => {
                    modals.openConfirmModal({
                      title: 'Reverter Cancelamento Viagem!',
                      centered: true,
                      children: (
                        <Text size='sm'>
                          Confirma que deseja reverter cancelamento da Viagem :{' '}
                          {viagem?.cliente.nome_simples} ?
                        </Text>
                      ),
                      labels: { confirm: 'Reverter', cancel: 'Cancelar' },
                      confirmProps: {
                        color: 'green',
                        variant: 'outline',
                        leftIcon: <IconUserHeart />,
                      },
                      cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                      onConfirm: () => {
                        ViagemService.viagemUncancelViagemRetrieve({ id: viagem?.id }).then(
                          () => {
                            fetchData();
                            notifications.show({
                              title: 'Revertido com sucesso ',
                              message: `Viagem de ${viagem?.cliente.nome_simples} está activa!`,
                              withCloseButton: true,
                              icon: <IconTrashX />,
                              color: 'green',
                            });
                          },
                          (reason: ApiError) => {
                            msgAPIError(reason);
                          }
                        );
                      },
                    });
                  }}>
                  Reverter cancelamento
                </Button>
              )}
              <Switch
                size={'sm'}
                label={'Bloqueada por falta de pagamento'}
                onLabel={<IconCheck />}
                offLabel={<IconX />}
                disabled={viagem?.cancelada}
                checked={viagem?.bloqueada_pagamentos}
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    ViagemService.viagemBloquearFaltaPagamentoRetrieve({
                      id: viagem.id,
                    }).then(
                      () => {
                        fetchData();
                      },
                      (reason) => {
                        msgAPIError(reason);
                      }
                    );
                  } else {
                    ViagemService.viagemDesbloquearFaltaPagamentoRetrieve({
                      id: viagem.id,
                    }).then(
                      () => {
                        fetchData();
                      },
                      (reason) => {
                        msgAPIError(reason);
                      }
                    );
                  }
                }}></Switch>
            </Group>
            <Button
              variant={'outline'}
              color={'red'}
              disabled={viagem?.valor_pago > 0}
              leftIcon={<IconTrashX />}
              onClick={() => {
                modals.openConfirmModal({
                  title: 'Remover Viagem!',
                  centered: true,
                  children: (
                    <Text size='sm'>
                      Confirma que deseja remover a Viagem : {viagem?.cliente.nome_simples} ?
                    </Text>
                  ),
                  labels: { confirm: 'Remover', cancel: 'Cancelar' },
                  confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                  cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                  onConfirm: () => {
                    ViagemService.viagemRemoverViagemRetrieve({ id: viagem?.id }).then(
                      () => {
                        notifications.show({
                          title: 'Removida com  sucesso ',
                          message: `Viagem de ${viagem?.cliente.nome_simples} foi removida!`,
                          withCloseButton: true,
                          icon: <IconTrashX />,
                          color: 'green',
                        });
                        navigate(`/campanha/${campanhaID}/grupo/${viagem.grupo.codigo}`);
                      },
                      (reason: ApiError) => {
                        msgAPIError(reason);
                      }
                    );
                  },
                });
              }}>
              Remover Viagem
            </Button>
            <Button
              variant={'outline'}
              color={'gray'}
              disabled={viagem?.cancelada}
              leftIcon={<IconHomeMove />}
              onClick={() => {
                setMoveGrupoVisible(true);
              }}>
              Mover Viagem
            </Button>
            <Switch
              label={'Chefe de Grupo'}
              disabled={viagem?.cancelada}
              size={'md'}
              onLabel={<IconCheck />}
              offLabel={<IconX />}
              checked={viagem?.chefe_de_grupo}
              onChange={(event) => {
                setLoading(true);
                ViagemService.viagemPartialUpdate({
                  id: viagem?.id,
                  requestBody: {
                    chefe_de_grupo: event.currentTarget.checked,
                  },
                }).then(
                  () => {
                    setLoading(false);
                    fetchData();
                  },
                  (reason) => {
                    msgAPIError(reason);
                    setLoading(false);
                  }
                );
              }}></Switch>
          </Flex>
        </Grid.Col>
      </Grid>

      <Divider
        size='xs'
        color={'green'}></Divider>
      <Tabs defaultValue={'main'}>
        <Tabs.List>
          <Tabs.Tab
            value={'main'}
            icon={<IconUsers />}>
            Viagem/Opções
          </Tabs.Tab>
          <Tabs.Tab
            value={'pagamentos'}
            icon={<IconReceipt2 />}>
            Pagamentos
          </Tabs.Tab>
          <Tabs.Tab
            value={'prestas'}
            icon={<IconCalculator />}>
            Prestações
          </Tabs.Tab>
          <Tabs.Tab
            value={'refmul'}
            icon={<IconCreditCard />}>
            Ref. Multibanco
          </Tabs.Tab>
          <Tabs.Tab
            value={'files'}
            icon={<IconPdf />}>
            Ficheiros
          </Tabs.Tab>
          <Tabs.Tab
            value={'notas'}
            icon={<IconNotes />}>
            Notas
          </Tabs.Tab>
          <Tabs.Tab
            value={'ilog'}
            icon={<IconRulerMeasure />}>
            Info. Logística
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={'main'}>
          <ScrollArea>
            <ComprasViagemView
              viagem={viagem}
              refresh={fetchData}
            />
          </ScrollArea>
        </Tabs.Panel>
        <Tabs.Panel value={'pagamentos'}>
          <ScrollArea>
            <PagamentosViagem
              viagem={viagem}
              doReload={fetchData}
            />
          </ScrollArea>
        </Tabs.Panel>
        <Tabs.Panel value={'prestas'}>
          <ScrollArea>
            <PrestacoesViagem viagem={viagem} />
          </ScrollArea>
        </Tabs.Panel>
        <Tabs.Panel value={'refmul'}>
          <ScrollArea>
            <RefMultibancoView
              viagem={viagem}
              refresh={fetchData}
            />
          </ScrollArea>
        </Tabs.Panel>
        <Tabs.Panel value={'files'}>
          <ViagemFiles />
        </Tabs.Panel>
        <Tabs.Panel value={'notas'}>
          <ViagemNotas
            notas={viagem?.notas}
            fetch={fetchData}
            notas_cliente={viagem?.cliente.notas_cliente}></ViagemNotas>
        </Tabs.Panel>
        <Tabs.Panel value={'ilog'}>
          <ViagemLogi
            viagem={viagem}
            fetch={fetchData}></ViagemLogi>
        </Tabs.Panel>
      </Tabs>

      <Modal
        centered
        title={'Mover Viagem:'}
        opened={moveGrupoVisible}
        size={'auto'}
        onClose={() => {
          setMoveGrupoVisible(false);
        }}>
        <MantineReactTable table={gruposTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setMoveGrupoVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(gruposTable).length == 0}
            onClick={() => {
              ViagemService.viagemMoverViajanteRetrieve({
                grupoDst: gruposTable.getSelectedRowModel().rows[0].original.codigo,
                id: viagem.id,
              }).then(
                () => {
                  fetchData();
                  setMoveGrupoVisible(false);
                },
                (reason) => {
                  msgAPIError(reason);
                  fetchData();
                  setMoveGrupoVisible(false);
                }
              );
            }}
            leftIcon={<IconUserEdit />}
            type='button'>
            Mover Viagem
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
}
