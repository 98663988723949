/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Cliente } from '../models/Cliente';
import type { ClienteRequest } from '../models/ClienteRequest';
import type { PaginatedClienteList } from '../models/PaginatedClienteList';
import type { PatchedClienteRequest } from '../models/PatchedClienteRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClienteService {

    /**
     * @returns PaginatedClienteList
     * @throws ApiError
     */
    public static clienteList({
        aceitouCondicoesDePrivacidade,
        apelido,
        contactoCasa,
        contactoTelemovel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        dataNascimentoAfter,
        dataNascimentoBefore,
        email,
        emailContactoUrgencia,
        emailPais,
        gdprAnomAplied,
        generoSexual,
        identificacao,
        isActive,
        lastLoginAfter,
        lastLoginBefore,
        moradaCodigoPostal,
        moradaLocalidade,
        moradaPais,
        moradaPorta,
        moradaRua,
        nacionalidade,
        nif,
        nomeCompleto,
        nomeContactoUrgencia,
        nomeMae,
        nomePai,
        nomeProprio,
        nomeSimples,
        notasCliente,
        ordering,
        page,
        pageSize,
        search,
        validadeIdentificacaoAfter,
        validadeIdentificacaoBefore,
    }: {
        aceitouCondicoesDePrivacidade?: boolean,
        apelido?: string,
        contactoCasa?: string,
        contactoTelemovel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        dataNascimentoAfter?: string,
        dataNascimentoBefore?: string,
        email?: string,
        emailContactoUrgencia?: string,
        emailPais?: string,
        gdprAnomAplied?: boolean,
        generoSexual?: string,
        identificacao?: string,
        isActive?: boolean,
        lastLoginAfter?: string,
        lastLoginBefore?: string,
        moradaCodigoPostal?: string,
        moradaLocalidade?: string,
        moradaPais?: string,
        moradaPorta?: string,
        moradaRua?: string,
        nacionalidade?: string,
        nif?: string,
        nomeCompleto?: string,
        nomeContactoUrgencia?: string,
        nomeMae?: string,
        nomePai?: string,
        nomeProprio?: string,
        nomeSimples?: string,
        notasCliente?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
        validadeIdentificacaoAfter?: string,
        validadeIdentificacaoBefore?: string,
    }): CancelablePromise<PaginatedClienteList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/cliente',
            query: {
                'aceitou_condicoes_de_privacidade': aceitouCondicoesDePrivacidade,
                'apelido': apelido,
                'contacto_casa': contactoCasa,
                'contacto_telemovel': contactoTelemovel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'data_nascimento_after': dataNascimentoAfter,
                'data_nascimento_before': dataNascimentoBefore,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'email_pais': emailPais,
                'gdpr_anom_aplied': gdprAnomAplied,
                'genero_sexual': generoSexual,
                'identificacao': identificacao,
                'is_active': isActive,
                'last_login_after': lastLoginAfter,
                'last_login_before': lastLoginBefore,
                'morada_codigo_postal': moradaCodigoPostal,
                'morada_localidade': moradaLocalidade,
                'morada_pais': moradaPais,
                'morada_porta': moradaPorta,
                'morada_rua': moradaRua,
                'nacionalidade': nacionalidade,
                'nif': nif,
                'nome_completo': nomeCompleto,
                'nome_contacto_urgencia': nomeContactoUrgencia,
                'nome_mae': nomeMae,
                'nome_pai': nomePai,
                'nome_proprio': nomeProprio,
                'nome_simples': nomeSimples,
                'notas_cliente': notasCliente,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
                'validade_identificacao_after': validadeIdentificacaoAfter,
                'validade_identificacao_before': validadeIdentificacaoBefore,
            },
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static clienteCreate({
        requestBody,
    }: {
        requestBody: ClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/cliente',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static clienteRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/cliente/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static clienteUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody: ClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/cliente/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static clientePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody?: PatchedClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/cliente/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static clienteDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/cliente/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static clienteGdprUserUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody: ClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/cliente/{id}/gdpr_user',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static clienteMapaRetrieve(): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/cliente/mapa',
        });
    }

}
