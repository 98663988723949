import React, { useEffect, useState } from 'react';
import { ComprasViagem } from '../../../../slidein_api';
import { Stack, Table, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { calculate_prestas, CalculatedPrestacao } from '../../../helpers';

export function PrestacoesViagem({ viagem }: { viagem: ComprasViagem }) {
  const [calcPrestas, setCalcPrestas] = useState<CalculatedPrestacao[]>([]);
  useEffect(() => {
    if (!viagem) return;
    setCalcPrestas(
      calculate_prestas(
        viagem.grupo.prestacoes,
        viagem.valor_total,
        viagem.compra_seguro.produto.valor,
        viagem.grupo.valor_presta_ref_min,
        viagem.valor_devido
      )
    );
  }, [viagem]);

  function prestaEstado(presta: CalculatedPrestacao) {
    if (presta.total_pago <= viagem.valor_pago) {
      return (
        <Text
          fw={'bold'}
          color={'green'}>
          Pago
        </Text>
      );
    } else if (
      dayjs(presta.data)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .isBefore(dayjs(viagem.data_hoje).set('hour', 0).set('minute', 0).set('second', 0))
    ) {
      return (
        <Text
          fw={'bold'}
          color={'red'}>
          Atrasado
        </Text>
      );
    } else {
      return 'Por Pagar';
    }
  }

  function presta_value(presta: CalculatedPrestacao) {
    if (presta.total_pago <= viagem.valor_pago) {
      return presta.valor_str;
    }
    if (presta.valor > viagem.valor_devido) {
      return (
        <div>
          <s>{presta.valor}€</s> {viagem.valor_devido}€ Restante
        </div>
      );
    } else {
      return presta.valor_str;
    }
  }

  return (
    <Stack>
      <Table>
        <thead>
          <tr>
            <th>Data limite</th>
            <th>Valor</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {calcPrestas.map((pp: CalculatedPrestacao) => {
            return (
              <tr key={pp.data}>
                <td>{dayjs(pp.data).locale('pt').format('YYYY-MM-DD')}</td>
                <td>{presta_value(pp)}</td>
                <td>{prestaEstado(pp)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
}
